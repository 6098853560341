<template>
  <div class=" h-100-vh form-rounded-inputs">
    <pull-refresh ref="scroller" :on-refresh="fnRefresh">
      <div>
        <div v-if=" !isLoading && storeList.length > 0 ">

          <div :class=" (itemsSelected.length > 0) ?  'mt-5 mt-md-4 pt-4 pt-md-1':''  ">
            <div class="row justify-content-between align-items-end">
              <div class="col-12 col-xl-8">
                <v-observer tag="form" ref="formPagination" class="animated fadeIn "
                  @submit.prevent="fnResetPagination()" v-show="(itemsSelected.length == 0)">
                  <div class="row">
                    <div class="ml-auto" :class=" mobileVersion ? 'col-12' : 'col-6 col-sm-6 col-lg-3 col-xl-3'  ">
                      <div class="form-group ">
                        <div class="row align-items-end">
                          <div class="col col-md-12">
                            <label class="w-100 text-truncte">
                              <span v-text=" $t('dashboard.products.name') "></span>
                            </label>
                            <div class="input-group">
                              <input type="text" class="form-control" placeholder="Keyword" v-model="pagination.name "
                                @change="fnResetPagination()">
                              <div class="input-group-append">
                                <button class="btn btn-success">
                                  <i class="fa fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="col-auto d-md-none ">
                            <div class="row">
                              <div class="col-auto pl-0">
                                <button type="button" v-if="mobileVersion"
                                  @click="showAdvancedFilters =! showAdvancedFilters" class="btn btn-info circle-35">
                                  <transition name="custom-classes-transition" mode="out-in"
                                    enter-active-class="animated jello">
                                    <i class=" fa fa-sliders-h" v-if="!showAdvancedFilters" key="open"></i>
                                    <i class=" fa fa-times" v-if="showAdvancedFilters" key="close"></i>
                                  </transition>
                                </button>
                              </div>
                              <div class="col-auto pl-0" v-if="userSession.store_id">
                                <router-link :to=" `/dashboard/new-product/${userSession.store_id}` "
                                  class="btn btn-success circle-35" >
                                  <i class="fa fa-plus-circle fa-lg d-md-none"></i>
                                </router-link>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="animated fadeIn"
                      :class=" mobileVersion ? 'col-12' : 'col-6 col-sm-6 col-lg-3 col-xl-3' "
                      v-show="showAdvancedFilters">
                      <div class="form-group ">
                        <label for="" v-text="$t('dashboard.products.store')"></label>
                        <div class="input-group">
                          <select class="custom-select" v-model="pagination.storeId " @change="fnResetPagination() ">
                            <option :value="null" v-text="$t('general.form.all')"> </option>
                            <option :value="store.search_store_id" v-for=" (store,index) in storeList " :key="index"
                              v-text=" store.name || fnStoreURL(store.url) || store.store_id  ">
                            </option>
                          </select>
                          <div class="input-group-append">
                            <button class="btn btn-success">
                              <i class="fa fa-store"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="animated fadeIn col-6 col-sm-6 col-lg-3 col-xl-3 " v-show="showAdvancedFilters">
                      <div class="form-group ">
                        <label for="" v-text="$t('dashboard.stores.status')"></label>
                        <div class="input-group">
                          <select class="custom-select" v-model="pagination.active " @change="fnResetPagination() ">
                            <option :value="null" v-text="$t('general.form.all')"> </option>
                            <option :value="true"> Online </option>
                            <option :value="false"> Offline </option>
                          </select>
                          <div class="input-group-append">
                            <button class="btn btn-success">
                              <i class="fa fa-ellipsis-h"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="animated fadeIn col-6 col-sm-6 col-lg-3 col-xl-3 " v-show="showAdvancedFilters">
                      <div class="form-group ">
                        <label for="" v-text="$t('dashboard.products.improvements')"></label>
                        <div class="input-group">
                          <select class="custom-select" v-model="pagination.improvements "
                            @change="fnResetPagination() ">
                            <option :value="null" v-text="$t('general.form.all')"> </option>
                            <option value="category" v-text="$t('general.form.category')"> </option>
                            <option value="width" v-text="$t('general.form.dimensions')"> </option>
                            <option value="tags" v-text="$t('general.form.tags')"> </option>
                            <option value="description" v-text="$t('general.form.description')"> </option>
                            <option value="image" v-text="$t('general.form.image')"> </option>
                          </select>
                          <div class="input-group-append">
                            <button class="btn btn-success">
                              <i class="fa fa-ellipsis-h"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-6 col-sm-6 col-lg-2 col-xl-1 d-none ">
                      <div class="form-group ">
                        <label for=""> <i class="fa fa-arrows-v"></i> Limit </label>
                        <select class=" form-control " v-model="pagination.limit " @change="fnResetPagination( );">
                          <option :value="limit" v-for=" (limit,index) in CONST.PAGINATION " :key="index"
                            v-text=" limit "> </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </v-observer>
              </div>
              <div class="col-12 col-lg-3 col-xl-2 d-none d-md-block ml-auto" v-if="userSession.store_id">
                <div class="form-group">
                  <router-link :to=" `/dashboard/new-product/${userSession.store_id}` " class="btn btn-success w-100">
                    <i class="fa fa-plus fa-lg d-md-none"></i>
                    <span v-text="$t('general.button.newProduct')" class="d-none d-md-block"></span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <!-- Mobile -->
          <table class=" table ecart-table d-lg-none " v-show=" productList.length > 0 ">
            <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" :checked="changeSelected" @click=" fnSelectAll() ">
                </th>
                <th class="col">
                  <label class="m-0" for="selectAll" v-text=" $t('dashboard.products.title') ">
                  </label>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for=" (item, index) in productList ">
                <tr :key="index + 'prodct'" :class=" item.active ? '' : 'item-offline'">
                  <td>
                    <input type="checkbox" :id=" 'selectItemMobile' + index " v-model="itemsSelected"
                      :value=" item.id ">
                  </td>
                  <td class=" ">
                    <div class="row  ">
                      <div class="col-3 col-sm-2 d-flex flex-column align-items-center ">
                        <router-link :to=" '/dashboard/products/' + item.id ">
                          <img loading="lazy" v-image="item.imageUrl" class=" img-cover-45 border ">
                        </router-link>

                        <button @click=" fnApiGetVariants(index,true) " class=" btn btn-success circle-25 mt-2"
                          v-if="item.variants">
                          <transition name="custom" mode="out-in" enter-active-class="animated jello">
                            <i class=" fa fa-caret-down" v-if="!item.show" key="open"></i>
                            <i class=" fa fa-caret-up" v-if="item.show" key="close"></i>
                          </transition>
                        </button>
                      </div>
                      <div class="col-9 col-sm-10 pl-0">
                        <label class=" my-0" :for=" 'selectItemMobile' + index ">
                          <p class=" m-0 f-w-600 ">
                            <span class="text-muted" v-text="'ID: '"></span>
                            <span v-text="item.ecart_id"></span>
                          </p>
                        </label>
                        <div class="row align-items-center justify-content-between">
                          <div class="col-10">
                            <p class="">
                              <strong>
                                <router-link :to=" '/dashboard/products/' + item.id " class="d-sm-none"
                                  v-html=" (item.name != null ? fnCutText(item.name, 20)  : item.id ) ">
                                </router-link>

                                <router-link :to=" '/dashboard/products/' + item.id " class="d-none d-sm-block"
                                  v-html=" (item.name != null ? fnCutText(item.name, 40)  : item.id ) ">
                                </router-link>
                              </strong>
                            </p>
                          </div>
                          <div class="col-2 pl-0 text-right">
                            <b-dropdown variant="success circle-30" :text="$t('general.actions')" no-caret right>
                              <template v-slot:button-content class="m-0">
                                <i class="fa fa-ellipsis-h"></i>
                              </template>
                              <b-dropdown-item :to=" '/dashboard/products/' + item.id ">
                                <p>
                                  <i class="fab fa-sm fa-wpforms mr-2"></i>
                                  <span v-text=" $t('general.details') "></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item :href="CONST.ECART.PRODUCT + item.slug" target="_blank">
                                <p>
                                  <i class="fa fa-sm fa-external-link-alt mr-2"></i>
                                  <span v-text="$t('tables.viewInStore')"></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item @click=" fnUpdateDiscount(index) ">
                                <p>
                                  <i class="fa fa-sm fa-percentage mr-2"></i>
                                  <span v-text="$t('tables.discount')"></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item @click="fnApiSyncProduct(index) " v-if=" item.ecommerce != 'Ecart'  ">
                                <p>
                                  <i class="fa fa-sm fa-sync mr-2"></i>
                                  <span v-text="$t('dashboard.stores.sync')"></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item @click=" fnApiChangeStatusProduct(index) ">
                                <p>
                                  <i class="fa fa-sm fa-power-off mr-2"></i>
                                  <span
                                    v-text=" !item.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item @click=" fnApiDeleteProduct(index) ">
                                <p>
                                  <i class="fa fa-sm fa-trash mr-2"></i>
                                  <span v-text="$t('general.button.delete')"></span>
                                </p>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>

                        <div class="row justify-content-between align-items-end mt-1">
                          <div class="col-5">
                            <p class="f-w-600 text-truncate m-0">
                              <span class="text-muted" v-text=" $t('dashboard.products.stock') + ': ' "></span>
                              <span v-text="  (  item.quantity > 0  ) ?  item.quantity : 0  ">
                              </span>
                            </p>
                          </div>
                          <div class="col-auto text-right ml-auto ">
                            <button class="btn f-w-600 p-0 " @click="fnUpdateDiscount(index)">
                              <span v-text="fnFormatPrice( item.price , item.currency )"
                                v-if="item.discountActive == null"></span>
                              <span
                                v-text="fnFormatPrice( item.discountActive.is_amount ? (item.price - item.discountActive.percent) : (item.price - ( (item.price / 100) * item.discountActive.percent ) ) , item.currency )"
                                v-else></span>
                              <small>
                                <strong class="text-success ml-1 " v-if="item.discountActive"
                                  v-text=" (item.discountActive.is_amount ? fnFormatPrice(item.discountActive.percent, item.currency) : (item.discountActive.percent + '%')) + ' OFF'  ">
                                </strong>
                              </small>
                              <b-badge variant="danger text-white ml-1 img-w-30" pill
                                v-if=" item.discountActive == null ">
                                <i class="fa fa-percentage fa-sm"></i>
                              </b-badge>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr :key="index + 'loadingVariants' " v-show=" loading.variants == item.id  ">
                  <td colspan="2">
                    <no-data isLoading sm>
                    </no-data>
                  </td>
                </tr>

                <div class="table-tr bg-dark-green f-12" :class=" item.active ? '' : 'item-offline'"
                  v-show=" item.show && item.variantsList && item.variantsList.length > 0 " :key=" index +'head'">
                  <td> </td>
                  <td> <strong v-text="$t('tables.variants')"> </strong>
                  </td>
                </div>

                <div v-for=" (variant,indexVar) in item.variantsList " :key="  'variantMobile' + variant.id  "
                  v-show=" item.show " class="border-4 table-tr"
                  :class="  { 'border-bottom ': (indexVar == item.variantsList.length -1 ) , ' bg-light  ': (indexVar%2 != 0 ), 'item-offline': (!item.active || !variant.active  ) } ">
                  <td></td>
                  <td class="">
                    <div class="row">
                      <div class="col-3 col-sm-2 d-flex flex-column align-items-center ">
                        <img loading="lazy" v-image="fnGetVariantImage( variant.image_id , item )"
                          class=" img-cover-45 border " alt="">
                      </div>
                      <div class="col pl-0">
                        <div class="row ">
                          <div class="col-6">
                            <p class="f-w-600">
                              <span class="text-muted text-uppercase" v-text=" $t('tables.sku')+ ': ' "></span>
                              <span v-text=" (variant.sku) ? variant.sku : '---' "> </span>
                            </p>
                          </div>
                          <div class="col-auto ml-auto">
                            <b-dropdown variant="success circle-30" :text="$t('general.actions')" no-caret right>
                              <template v-slot:button-content class="m-0">
                                <i class="fa fa-ellipsis-h"></i>
                              </template>

                              <b-dropdown-item @click=" fnApiChangeStatusVariant( index , indexVar) ">
                                <p>
                                  <i class="fa fa-sm fa-power-off mr-2"></i>
                                  <span
                                    v-text=" !variant.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                                </p>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                          <div class="col-12 ">
                            <p>
                              <b v-text=" variant.name "></b>
                            </p>
                          </div>
                        </div>
                        <div class="f-w-500">
                          <p>
                            <span class="text-muted" v-text=" $t('dashboard.products.dimensions') + ': ' "></span>
                            <span
                              v-html=" (variant.dimensions && variant.dimensions.width) ? fnFormatDimenisons( variant.dimensions ) : '---' "></span>
                          </p>
                        </div>
                        <div class="row">
                          <div class="col-6 ">
                            <p class="f-w-600">
                              <span class="text-muted" v-text=" $t('dashboard.products.stock') + ': ' "></span>
                              <span v-text=" (  variant.quantity > 0  ) ?  variant.quantity : 0 ">
                              </span>
                            </p>
                          </div>
                          <div class="col-12 ml-auto text-right">
                            <p class="f-w-500">
                              <span v-if="variant.discount == null"
                                v-text="fnFormatPrice( variant.price , item.currency ) "></span>
                              <span v-else
                                v-text=" fnFormatPrice( (variant.discount.is_amount) ? (variant.price - variant.discount.percent) : (variant.price - ( (variant.price /100) * variant.discount.percent)) , item.currency ) ">
                              </span>
                              <small v-if="variant.discount">
                                <strong class="text-success ml-2"
                                  v-text="((variant.discount.is_amount) ? fnFormatPrice(variant.discount.percent, item.currency ) : (variant.discount.percent + '%' ) )+ ' OFF' "></strong>
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </div>
              </template>
            </tbody>
          </table>

          <!-- Desktop -->
          <div class=" d-none d-lg-block " v-show=" productList.length > 0 ">
            <table class="table ecart-table">
              <thead>
                <tr class=" text-center ">
                  <th>
                    <input type="checkbox" id="selectAll" :checked="changeSelected" @click=" fnSelectAll() ">
                  </th>
                  <th v-text=" $t('dashboard.products.image')"> </th>
                  <th class=" text-left w-25 " v-text=" $t('dashboard.products.productName')">
                  </th>
                  <th v-text=" $t('dashboard.products.store')"> </th>
                  <th v-text=" $t('dashboard.products.price')"> </th>
                  <th v-text=" $t('dashboard.products.stock')"> </th>
                  <th v-text=" $t('general.actions')"> </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in productList">
                  <tr class="" :key=" index " :class=" item.active ? '' : 'item-offline'">
                    <td class=" text-center ">
                      <input type="checkbox" v-model=" itemsSelected " :value=" item.id ">
                      <button @click=" fnApiGetVariants(index,true) " class="btn btn-sm btn-success btn-rounded mx-auto"
                        v-if="item.variants">
                        <i class="fa" :class="  item.show ? 'fa-caret-up':'fa-caret-down' "></i>
                      </button>
                    </td>

                    <td class=" text-center ">
                      <router-link :to=" '/dashboard/products/' + item.id ">
                        <img loading="lazy" v-image="item.imageUrl" class=" img-cover-50 border">
                      </router-link>
                    </td>

                    <td>
                      <div class=" row">
                        <div class="col-12 ">
                          <p class=" m-0 ">
                            <small>
                              <span v-text="'ID: '"></span>
                              <span class="f-w-600" v-text="item.ecart_id"></span>
                            </small>
                          </p>
                        </div>
                        <div class="col-12 ">
                          <p class=" text-wrap ">
                            <router-link :to=" '/dashboard/products/' + item.id "
                              :class=" item.active ? '' : 'text-warning' ">
                              <strong v-html=" (item.name != null ? item.name : item.id ) ">
                              </strong>
                            </router-link>
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>
                      <p v-text="item.store_name" class="text-center"> </p>
                    </td>

                    <td>
                      <div class="row">
                        <div class="col-auto mx-auto">
                          <p class="text-center">
                            <button class="btn btn-sm btn-outline-light" @click="fnUpdateDiscount(index)">
                              <strong>
                                <span v-text="fnFormatPrice( item.price , item.currency )"
                                  v-if="item.discountActive == null"></span>
                                <span
                                  v-text="fnFormatPrice( item.discountActive.is_amount ? (item.price - item.discountActive.percent) : (item.price - ( (item.price / 100) * item.discountActive.percent ) ) , item.currency )"
                                  v-else></span>
                              </strong>
                              <i class="fa fa-exclamation-circle text-warning expretion "
                                v-if=" item.discountActive == null "></i>
                              <i class="fa fa-check-circle text-success expretion " v-else></i>
                            </button> <br>
                            <small>
                              <strong class="text-success ml-1 " v-if="item.discountActive"
                                v-text=" (item.discountActive.is_amount ? fnFormatPrice(item.discountActive.percent, item.currency) : (item.discountActive.percent + '%')) + ' OFF' ">
                              </strong>
                            </small>
                          </p>
                        </div>
                      </div>
                    </td>

                    <td class=" text-center ">
                      <p :class=" (item.quantity > 0)? '':'text-danger' "
                        v-text=" (item.quantity > 0)? item.quantity : '0' ">
                      </p>
                    </td>

                    <td class=" text-center ">
                      <b-dropdown variant="success circle-30" right no-caret>
                        <template v-slot:button-content class="m-0">
                          <i class="fa fa-ellipsis-h fa-lg"></i>
                        </template>
                        <b-dropdown-item :to=" '/dashboard/products/' + item.id ">
                          <p>
                            <i class="fab fa-sm fa-wpforms mr-2"></i>
                            <span v-text=" $t('general.details') "></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item :href="CONST.ECART.PRODUCT + item.slug" target="_blank">
                          <p>
                            <i class="fa fa-sm fa-external-link-alt mr-2"></i>
                            <span v-text="$t('tables.viewInStore')"></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click=" fnUpdateDiscount(index) ">
                          <p>
                            <i class="fa fa-sm fa-percentage mr-2"></i>
                            <span v-text="$t('tables.discount')"></span>
                          </p>
                        </b-dropdown-item>

                        <b-dropdown-item @click="fnApiSyncProduct(index) " v-if=" item.ecommerce != 'Ecart'  ">
                          <p>
                            <i class="fa fa-sm fa-sync mr-2"></i>
                            <span v-text="$t('dashboard.stores.sync')"></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-item @click=" fnApiChangeStatusProduct(index) ">
                          <p>
                            <i class="fa fa-sm fa-power-off mr-2"></i>
                            <span
                              v-text=" !item.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                          </p>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click=" fnApiDeleteProduct(index) ">
                          <p>
                            <i class="fa fa-sm fa-trash mr-2"></i>
                            <span v-text="$t('general.button.delete')"></span>
                          </p>
                        </b-dropdown-item>
                      </b-dropdown>

                    </td>
                  </tr>
                  <tr :key="index + 'loadingVariants' " v-show=" loading.variants == item.id  ">
                    <td colspan="7">
                      <no-data isLoading sm>
                      </no-data>
                    </td>
                  </tr>

                  <div class="table-tr bg-dark-green f-12" :class=" item.active ? '' : 'item-offline'"
                    v-show=" item.show && item.variantsList && item.variantsList.length > 0 " :key=" index +'head'">
                    <td class=" text-center " colspan="2"> </td>
                    <td colspan="5"> <strong v-text="$t('tables.variantName')"> </strong>
                    </td>
                  </div>

                  <div class=" table-tr bg-dark " v-for=" (variant,varIndex) in item.variantsList"
                    :class="  { 'border-bottom border-bottom-4 ': (varIndex == (item.variantsList.length -1) ) , 'bg-white': (varIndex%2 == 0 ), 'item-offline' : (!variant.active || !item.active) } "
                    v-show=" item.show  " :key=" variant.id + index ">
                    <td></td>
                    <td class="text-center ">
                      <img loading="lazy" v-image="fnGetVariantImage( variant.image_id , item )"
                        class=" img-cover-30 border">
                    </td>

                    <td>
                      <p class="text-uppercase">
                        <small>
                          <span class="text-uppercase f-w-600" v-text=" $t('tables.sku')+ ': ' "></span>
                          <span v-text=" (variant.sku) ? variant.sku : $t('general.form.none') "> </span>
                        </small>
                      </p>
                      <p class="m-0 text-wrap ">
                        <strong v-text=" variant.name "> </strong>
                      </p>
                    </td>
                    <td>
                      <p class="text-capitalize text-center " v-if=" variant.dimensions && variant.dimensions.width ">
                        <span v-html="fnFormatDimenisons( variant.dimensions )"></span>
                      </p>
                    </td>

                    <td class=" text-center ">
                      <p>
                        <span v-if="variant.discount == null"
                          v-text="fnFormatPrice( variant.price , item.currency ) "></span>
                        <span v-else
                          v-text=" fnFormatPrice( (variant.discount.is_amount) ? (variant.price - variant.discount.percent) : (variant.price - ( (variant.price /100) * variant.discount.percent)) , item.currency ) ">
                        </span>
                        <br>
                        <small v-if="variant.discount">
                          <strong class="text-success"
                            v-text="((variant.discount.is_amount) ? fnFormatPrice(variant.discount.percent, item.currency ) : (variant.discount.percent + '%' ) )+ ' OFF' "></strong>
                        </small>
                      </p>
                    </td>

                    <td class=" text-center">
                      <p v-text=" ( variant.quantity > 0 ) ? variant.quantity : 0 ">
                      </p>
                    </td>

                    <td class="text-center">
                      <b-dropdown variant="success circle-30" right no-caret>
                        <template v-slot:button-content class="m-0">
                          <i class="fa fa-ellipsis-h fa-lg"></i>
                        </template>
                        <b-dropdown-item @click=" fnApiChangeStatusVariant( index , varIndex) ">
                          <p>
                            <i class="fa fa-sm fa-power-off mr-2"></i>
                            <span
                              v-text=" !variant.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                          </p>
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>

                  </div>
                </template>
              </tbody>
            </table>
          </div>

          <no-data :dataMessage="$t('noDataMessages.productList')" v-show=" productList.length == 0"
            :isLoading="isLoading" :showBtn="false">
          </no-data>

          <div
            v-if=" (itemsSelected.length == 0) && pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
            <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
              @input="fnApiGetProducts" align="fill" :first-text="$t('general.button.first')"
              :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')"
              :last-text="$t('general.button.last')" class="d-none d-md-flex">
            </b-pagination>
            <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
              @input="fnApiGetProducts" align="fill" class="d-md-none">
            </b-pagination>
          </div>

        </div>
        <no-data v-else :dataMessage="$t('noDataMessages.storeList')" :isLoading="isLoading"
          :btnMessage=" $t('messages.addNewStore') " @btn-fn="fnGlobalStoreIntegrationLink(true,device)">
        </no-data>
      </div>

    </pull-refresh>
    <product-discount class="m-0" :productInfo=" productInfo " :showModalDiscount="modalDiscount"
      @update-discount=" fnUpdateProductDiscount "></product-discount>

    <ProductsBulk class="m-0" :productArray=" itemsSelected " :typeQuick=' typeQuick ' :showQuickModal="modalQuick"
      @update-products=" fnUpdateProductsQuick " @close-modal=" fnResetQuick " />

    <b-modal :title="$t('modalTitles.sellOutOfStock')" content-class="card form-rounded-inputs card-ecart"
      header-class="card-header p-3 " v-model="modalSellOutOfStock" @hidden="fnResetSellOutOfStock()"
      cancel-variant="danger" size="sm" centered hide-header-close>
      <div>
        <p v-text="$t('modalMessages.sellOutOfStcokStatus')"></p>
        <p v-text="$t('modalMessages.sellOutOfStockMessage')"></p>
      </div>
      <template v-slot:modal-footer>

        <div class="d-flex justify-content-end w-100">

          <div class="ml-auto">
            <b-button variant="none" size="sm" class=" mr-2"
              @click=" modalSellOutOfStock = !modalSellOutOfStock ; fnResetSellOutOfStock() "
              v-text=" $t('general.button.close') ">
            </b-button>
            <b-button variant="success" size="sm" class=" mr-2" @click=" fnApiSellOutOfStock() "
              v-text="  $t('general.button.update') ">
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <float-container :show=" itemsSelected.length > 0 " classes="bg-success">
      <template slot="content">
        <div class="row">
          <div class="col">
            <label for="">
              <strong>
                <i class="fa fa-list-alt mr-1"></i>
                <span v-text="$t('modalMessages.bulkActionsTitle')"></span>,
              </strong>
              <span class="text-right" v-text=" $t('general.items') + ': ' + itemsSelected.length "></span>
            </label>
          </div>
          <div class="col-auto">
            <button class="btn text-white p-0" @click="fnShowQuickModal('close')">
              <i class="fa fa-window-close fa-lg "></i>
            </button>
          </div>
        </div>
        <div class="row ">
          <div class="col">
            <div class="form-group m-0 ">

              <select class="custom-select rounded-pill" v-model="typeQuick"
                @change="fnShowQuickModal(  $event.target.value ) ">
                <option :value="null" disabled> <span v-text=" $t('general.options.actions') "> </span>
                </option>
                <option value="activate" v-text=" $t('general.button.activate') "> </option>
                <option value="deactivate" v-text=" $t('general.button.deactivate') "> </option>
                <hr>
                <option value="category" v-text=" $t('general.options.category') "></option>
                <option value="tags" v-text=" $t('general.options.tags') "></option>
                <option value="dimensions" v-text=" $t('general.options.dimensions') "></option>
                <option value="sellOutOfStock" v-text=" $t('general.options.sellOutOfStock') "> </option>
                <option value="prepTime" v-text=" $t('general.options.prepTime') "> </option>
                <hr>
                <option value="delete" v-text=" $t('general.button.delete') "> </option>
                <hr>
                <option value="close" v-text="$t('messages.closeBulkActions')">
                </option>
              </select>
            </div>
          </div>
        </div>
      </template>
    </float-container>

  </div>
</template>

<script>
  import ProductsBulk from './components/product-quick-actions-modal';
  import productDiscount from '@/components/dashboard/product/product-discount-modal';
  import PullRefresh from "@/components/PullRefresh.vue";
  import {
    mapActions,
    mapMutations,
    mapState
  } from 'vuex';
  export default {
    components: {
      productDiscount,
      ProductsBulk,
      PullRefresh,
    },
    data() {
      return ({
        isLoading: true,
        showAdvancedFilters: true,
        modalSellOutOfStock: false,
        modalImprovements: false,
        productList: [],
        storeList: [],
        options: [],
        //pagination
        pagination: {
          storeId: null,
          name: null,
          active: null,
          page: 1,
          limit: 50,
          total: null,
          improvements: null,
        },
        // CATEGORIE
        tempIndexProduct: null,
        modalDimensions: false,
        modalDiscount: false,
        modalQuick: false,
        productInfo: {
          variants: [],
        },
        itemsSelected: [],
        typeQuick: null,
        tempProductIndex: null,
        loading: {
          variants: false,
        }
      })
    },
    computed: {
      ...mapState(['mobileVersion', 'userSession', 'device']),
      changeSelected() {
        if (this.productList.length > 0) {
          return this.itemsSelected.length == this.productList.length;
        } else {
          return false;
        }
      },
    },
    watch: {
      mobileVersion() {
        this.fnMobileUI();
      }
    },
    methods: {
      fnMobileUI() {
        if (this.mobileVersion) {
          this.showAdvancedFilters = false;
        } else {
          this.showAdvancedFilters = true;
        }
      },
      async fnApiGetVariants(index, show) {
        this.loading.variants = this.productList[index].id;
        if (this.productList[index].variantsList.length == 0) {
          await axios.get(`products/${this.productList[index].id}/variants`).then(async (response) => {

            if (this.productList[index].discountActive) {
              response.data.map(variant => {
                if (this.productList[index].discountActive.variant_ids.find(varId => varId == variant.id)) {
                  variant.discount = this.productList[index].discountActive;
                } else {
                  variant.discount = null;
                }
              });
            }
            this.productList[index].variantsList = response.data;

          }).catch(error => {});
        } else {}
        this.loading.variants = null;
        if (show && (this.productList[index].variantsList.length > 0)) {
          this.productList[index].show = !this.productList[index].show;
        }
      },
      async fnApiGetProducts() {
        this.isLoading = true;
        let {
          name,
          storeId,
          active,
          limit,
          improvements,
          page
        } = this.$lodash.clone(this.pagination);
        let tempPagination = {
          page,
          limit
        };
        if (name != null && name != '') {
          tempPagination.name = name
        };
        if (storeId != null && storeId != '') {
          tempPagination.store_id = storeId
        };
        if (active !== null && active !== '') {
          tempPagination.active = active
        };
        if (improvements !== null && improvements !== '') {
          tempPagination.improvements = improvements
        };

        this.$router.replace({
          query: tempPagination
        }).catch(err => {})

        await axios.get('products', {
          params: tempPagination
        }).then(async (response) => {
          if (response.data && response.data.length > 0) {
            response.data = response.data.map((product) => {
              product.show = false;
              product.selected = false;
              product.variantsList = [];
              product.discountActive = null;
              if (product.discounts && (product.discounts.length > 0)) {
                product.discountActive = product.discounts.find(disc => disc.active);
              }
              return product;
            });
          }
          this.productList = response.data;
          setTimeout(() => {
            window.scroll({
              top: 0,
              behavior: 'smooth'
            });
          }, 1000);

        }).catch(error => {});
        this.isLoading = false;
      },

      fnApiGetStores() {
        axios.get('stores').then(async (response) => {
          if (response.data && response.data.length > 0) {
            this.storeList = response.data;
          } else {
            this.isLoading = false;
          }
        }).catch(error => {});
      },

      fnApiGetCount() {
        let {
          name,
          storeId,
          improvements,
          active
        } = this.$lodash.cloneDeep(this.pagination);
        let tempPagination = {};
        if (name != null && name != '') {
          tempPagination.name = name
        };
        if (storeId != null && storeId != '') {
          tempPagination.store_id = storeId
        };
        if (active !== null && active !== '') {
          tempPagination.active = active
        };
        if (improvements !== null && improvements !== '') {
          tempPagination.improvements = improvements
        };

        axios.get('products/count', {
          params: tempPagination
        }).then(async (response) => {
          this.pagination.total = response.data.count;
        }).catch(error => {});
      },

      fnResetPagination() {
        this.pagination.page = 1;
        this.fnApiGetProducts();
        this.fnApiGetCount();
      },

      fnFirstPagination() {
        this.fnResetPagination();
        this.fnApiGetStores();
      },

      fnApiChangeStatusProduct(index) {
        axios.put(`products/${ this.productList[index].id }`, {
          active: !this.productList[index].active
        }).then((response) => {
          this.productList[index].active = !this.productList[index].active;
          this.$toasted.global.infoMessage(this.$t('toasted.info.update'));
        }).catch((error) => {
          if (error.response.data.message = 'The product is out stock.') {
            this.modalSellOutOfStock = true;
            this.tempProductIndex = index;
          }

        });
      },

      fnApiChangeStatusVariant(indexProduct, indexVariant) {
        let productId = this.productList[indexProduct].id;
        let variantId = this.productList[indexProduct].variantsList[indexVariant].id;

        axios.put(`products/${ productId }/variants/${ variantId }`, {
          active: !this.productList[indexProduct].variantsList[indexVariant].active
        }).then((response) => {
          this.$toasted.global.infoMessage(this.$t('toasted.info.update'));
          this.productList[indexProduct].variantsList[indexVariant].active = !this.productList[indexProduct]
            .variantsList[indexVariant].active;
        }).catch((error) => {
          if (error.response.data.message = 'The product is out stock.') {
            this.modalSellOutOfStock = true;
            this.tempProductIndex = indexProduct;
          }
        });
      },

      fnGetVariantImage(image_id, product) {
        let defalutImg = product.imageUrl;
        if (product.images.length > 0) {
          if (image_id != null) {
            let tempImage = this.$lodash.find(product.images, {
              id: image_id
            });
            if (tempImage != undefined) {
              return tempImage.url;
            } else {
              return defalutImg;
            }
          } else {
            return defalutImg;
          }
        } else {
          return defalutImg;
        }
      },

      // Dimensions
      fnUpdateDimensions(index) {
        this.tempIndexProduct = index;
        this.productInfo = this.$lodash.cloneDeep(this.productList[index]);
        this.modalDimensions = !this.modalDimensions;
      },
      fnUpdateProductDimensions(product) {
        this.productList[this.tempIndexProduct].dimensions = product.dimensions;
        this.productList[this.tempIndexProduct].variants = product.variants;
      },

      fnUpdateProductCategory(category) {
        this.productList[this.tempIndexProduct].category.id = category.id;
        this.productList[this.tempIndexProduct].category.name = category.name;
      },

      fnSelectAll() {
        if (this.itemsSelected.length < this.productList.length) {
          this.itemsSelected = [];
          for (let index in this.productList) {
            this.itemsSelected.push(this.productList[index].id);
          }
        } else {
          this.itemsSelected = [];
        }
      },

      // Quick actions
      fnShowQuickModal(typeQuick) {
        if (typeQuick != 'close') {
          if (typeQuick && this.itemsSelected.length > 0) {
            this.typeQuick = typeQuick;
            this.modalQuick = !this.modalQuick;
          } else {
            this.$bvModal.msgBoxOk(this.$t('modalMessages.bulkActionsError'), {
                title: this.$t('modalMessages.bulkActionsTitle'),
                okVariant: 'success',
                centered: true,
                okTitle: this.$t('general.button.close'),
              })
              .then(value => {
                this.boxOne = value
              })
              .catch(err => {})

          }
        } else {
          this.typeQuick = null;
          this.itemsSelected = [];
        }

      },

      fnUpdateProductsQuick(data) {
        for (let id of this.itemsSelected) {
          let tempIndex = this.$lodash.findIndex(this.productList, ['id', id]);
          switch (this.typeQuick) {
            case 'tags':
              // this.productList[tempIndex].tags = data;
              break;
            case 'category':
              this.productList[tempIndex].category = data;
              break;
            case 'dimensions':
              this.productList[tempIndex].dimensions = data;
              break;
            case 'delete':
              this.productList.splice(tempIndex, 1);
              break;
            case 'deactivate':
              this.productList[tempIndex].active = false;
              break;
            case 'activate':
              this.productList[tempIndex].active = true;
              break;
            case 'sellOutOfStock':
              let fail = undefined;
              if (data.fail_ids != undefined) {
                fail = data.fail_ids.find(element => element == this.productList[tempIndex].id);
              }
              if (fail == undefined) {
                this.productList[tempIndex].active = true;
                if (this.productList[tempIndex].variants.length > 1) {
                  for (let index in this.productList[tempIndex].variants) {
                    this.productList[tempIndex].variants[index].active = true;
                  }
                }
              }
              break;
          }
        }
        this.typeQuick = null;
        this.itemsSelected = [];
      },

      fnResetQuick() {
        this.typeQuick = null;
      },
      // SellOfStock
      fnResetSellOutOfStock() {
        this.tempProductIndex = null;
      },
      fnApiSellOutOfStock() {
        axios.put(`products/${ this.productList[this.tempProductIndex].id }`, {
          sell_out_stock: true
        }).then((response) => {
          this.modalSellOutOfStock = false;
          this.fnApiChangeStatusProduct(this.tempProductIndex);

          for (let index in this.productList[this.tempProductIndex].variants) {
            this.productList[this.tempProductIndex].variants[index].active = true;
          }
          this.productList[this.tempProductIndex].active = true;
          this.fnResetSellOutOfStock();
          this.$toasted.global.infoMessage(this.$t('toasted.info.productSellOuOfStockUpdate'));
        }).catch((error) => {});
      },
      // Discount
      async fnUpdateDiscount(index) {

        if (this.productList[index].variants && this.productList[index].variantsList.length == 0) {
          await this.fnApiGetVariants(index);
        }

        this.tempIndexProduct = index;
        this.productInfo = this.$lodash.cloneDeep(this.productList[index]);
        this.modalDiscount = !this.modalDiscount;
      },

      fnUpdateProductDiscount(discount, vaiantsList) {
        if (vaiantsList) {
          this.productList[this.tempIndexProduct].variantsList.map(variant => {
            if (vaiantsList.find(varId => varId == variant.id)) {
              variant.discount = discount;
            } else {
              variant.discount = null;
            }
          })
        }
        this.productList[this.tempIndexProduct].discounts.unshift(discount);
        this.productList[this.tempIndexProduct].discountActive = discount;
      },

      //improvements
      fnShowImprovements(index) {
        this.productInfo = this.$lodash.cloneDeep(this.productList[index]);
        if (this.productInfo.completed < 100) {
          this.modalImprovements = !this.modalImprovements;
        }
      },
      fnUpdateImprovents(data) {
        let index = this.$lodash.findIndex(this.productList, ['id', data.id]);
        this.productList[index].name = data.name;
        this.productList[index].description = data.description;
        this.productList[index].tags = data.tags;
        this.productList[index].category = data.category;
        this.productList[index].dimensions = data.dimensions;
        this.productList[index].completed = data.completed;
      },

      fnApiDeleteProduct(index) {
        this.$bvModal.msgBoxConfirm(this.$t('messages.deleteProduct'), {
            title: this.$t('general.button.confirmation'),
            size: 'md',
            okVariant: 'success',
            okTitle: this.$t('general.button.delete'),
            cancelTitle: this.$t('general.button.cancel'),
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }).then(value => {
            if (value) {
              axios.delete(`products/delete/${ this.productList[index].id }`).then(response => {
                this.$toasted.global.infoMessage(this.$t('toasted.info.productDelete'));
                this.productList.splice(index, 1);
              }).catch(error => {});
            }
          })
          .catch(err => {})
      },
      fnApiSyncProduct(index) {
        axios.get(`products/synchronize/ecommerce/${this.productList[index].id}`).then((response) => {
          this.productList[index] = {
            ...this.productList[index],
            ...response.data
          };
          this.$toasted.global.infoMessage(this.$t('toasted.info.productSync'));
        }).catch(error => {});
      },
      async fnRefresh(done) {
        await this.fnFirstPagination();
        done();
      }
    },
    async mounted() {
      this.fnMobileUI();

      this.$route.query.page ? (this.pagination.page = this.$route.query.page) : false;
      this.$route.query.name ? (this.pagination.name = this.$route.query.name) : false;
      this.$route.query.active ? (this.pagination.active = this.$route.query.active) : false;
      this.$route.query.improvements ? (this.pagination.improvements = this.$route.query.improvements) : false;
      this.$route.query.store_id ? (this.pagination.storeId = this.$route.query.store_id) : false;
      await this.fnFirstPagination();
    },

  }
</script>

<style lang="scss">
  .img-product {
    height: 50px;
    background-size: cover;
  }

  .col-truncated {
    flex: 1;
    min-width: 0;

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>